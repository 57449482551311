<template>
  <div>
    <!-- 供应商 -->
    <supplier :dictType="dictType" v-if="dictType === '1'" />
  </div>
</template>

<script>
import { resolutionAPi } from '@/api/businessApi'
import { getDictLists } from '@/filters/fromDict'
import Supplier from './production-type/supplier.vue'
// import Storage from '@/utils/storage'
export default {
  components: { Supplier },
  props: {},
  data () {
    return {
      dictType: '1' // 制作类型
    }
  },
  // 计算属性 类似于data概念
  computed: {
    getDictLists () {
      return getDictLists
    },
    api () {
      return resolutionAPi
    },
    tags () {
      return JSON.parse(sessionStorage.getItem('routerList'))
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 页面跳转方法
    jump () {
      this.$router.push({ path: this.tags.path })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // this.getBatch()
  }
}
</script>
<style lang='scss' scoped>
</style>
